import React from 'react'
import { Select as NSelect } from '@rmwc/select'

import '@rmwc/select/styles'
import './Select.scss'

export default function Select (props) {
  const {
    className = '',
    name = '',
    label,
    required = false,
    fieldErrors = {},
    value,
    options,
    onChange,
    disabled,
    setter
  } = props
  const id = name.replace(/[^a-zA-Z0-9]/g, '-')
  const cleanName = name.replace(/\s*\*$/, '')

  return (
    <NSelect
      id={id}
      disabled={disabled}
      invalid={fieldErrors[cleanName] != null}
      className={`nselect ${className}`}
      label={`${label != null ? label : name}`}
      required={required}
      value={value}
      options={options}
      outlined
      onChange={setter != null
        ? evt => { setter(evt.target.value) }
        : onChange}
      enhanced
    />
  )
}
