import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../App'

export default function TermsOfUsePage (props) {
  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Terms Of Use' })
  }, [])

  return (
    <div className='page terms'>

      <div className='page-header'>
        <div className='contents'>
            Website Disclaimer
        </div>
      </div>

      <div className='page-body'>
        <div className='paragraph'>
            The information contained in this website is provided by Francis Tuttle Technology Center and
            is for general information purposes only. Francis Tuttle Technology Center makes no representations
            or warranties of any kind, express or implied, about the completeness, accuracy, reliability,
            suitability or availability with respect to the website or the information, products, services,
            or related graphics contained on the website for any purpose. Any reliance you place on such information
            is therefore strictly at your own risk.
        </div>

        <div className='paragraph'>
            In no event will Francis Tuttle Technology Center be liable for any loss or damage including without limitation,
            indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits
            arising out of, or in connection with, the use of this website.
        </div>

        <div className='paragraph'>
            Through this website you are able to link to other websites which are not under the control of
            Francis Tuttle Technology Center. We have no control over the nature, content and availability of those sites.
            The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
        </div>

        <div className='paragraph'>
            Every effort is made to keep the website up and running smoothly. However, Francis Tuttle Technology Center takes
            no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.
        </div>

        <div className='paragraph'>
            Students must be 16 years old or older in order to enroll in the majority of the classes.
            There are exceptions, however, for some classes, such as ACT Prep.
            To check if a class has an exception to the age requirement, please contact our enrollment offices at 405-717-4900.
        </div>
      </div>

    </div>
  )
}
