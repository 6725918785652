import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu'
import Icon from './Icon'
import List from './List'
import { connect } from 'react-redux'

import '@rmwc/menu/styles'
import './MainMenu.scss'

// --vars

// --components
function MainMenu ({ pageTitle, cart }) {
  const [open, setOpen] = useState(false)

  function toggle () {
    setOpen(!open)
  }
  function close () {
    setOpen(false)
  }
  return (
    <span className='main-menu'>
      <MenuSurfaceAnchor>
        <Menu
          open={open}
          onSelect={(evt) => close()}
          onClose={(evt) => close()}
        >
          <List>
            {cart.reservations.length > 0 &&
              <>
                <MainMenuLink to='/cart' icon='shopping_cart' text='Checkout' />
              </>}
            <MainMenuLink to='/students' icon='people_alt' text='Add Student' />
            <MainMenuLink to='/ll-course-categories' icon='school' text='Classes' />
            <MainMenuLink to='/sq-courses' icon='sunny' text='Summer Quest' />
          </List>
        </Menu>
      </MenuSurfaceAnchor>
      <div onClick={() => toggle()} className='main-menu-link'>
        <Icon icon={{ icon: 'menu', size: 'xlarge' }} />
      </div>
    </span>
  )
}

function MainMenuLink ({ to, icon, text }) {
  const history = useHistory()

  return (
    <MenuItem
      onClick={() => {
        history.push(to, 'replace')
      }}
      tabIndex={0}
      className='menu-item'
    >
      <div className='menu-item--inner'>
        <Icon icon={icon} />
        {text}
      </div>
    </MenuItem>
  )
}

// --functions

// --mappings
// export default MainMenu
export default connect(state => ({
  cart: state.cart
}))(MainMenu)
