import React, { useEffect, useContext } from 'react'
import { HeaderContext } from '../App'
import { connect } from 'react-redux'

import SVGIcon from '~/components/SVGIcon'
import Button from '~/components/Button'

function ReceiptPage (props) {
  if (props.cart.reservations.length > 0) {
    props.history.push('/cart')
  }

  const { setHeaderInfo } = useContext(HeaderContext)

  useEffect(() => {
    setHeaderInfo({ pageTitle: 'Receipt' })
  }, [])

  const captureId = props.match.params.id

  return (
    <div className='page receipt'>

      <div className='page-header'>
        <div className='contents'>
            Thank you for enrolling!
        </div>
      </div>

      <div className='page-body'>
        <div className='row congrats'>
          <SVGIcon icon='checkcircle' />
            Payment received
        </div>
        <div className='paragraph'>
            You will receive a confirmation email shortly.
        </div>
        <div className='paragraph'>
            Your Transaction ID is <span className='number'>{captureId}</span>
        </div>
        <div className='paragraph'>
            We look forward to having your student join us.
        </div>
        <div className='paragraph'>
          <div>If you have any questions or need further assistance, please call</div>
          <div>405-717-4900.</div>
        </div>
        <div>
          <Button
            label='Classes'
            unelevated
            onClick={() => props.history.push('/ll-course-categories')}
          />
        </div>
      </div>
    </div>
  )
}

export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(ReceiptPage)
