import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import recaptcha from '~/helpers/loadRecaptcha'
import http, { wrapButtonToggle } from '~/http'
import ErrorMessage from '~/components/ErrorMessage'
import Button from '~/components/Button'
import SectionSearchResult from '~/components/SectionSearchResult'
import { terseDate, fullDate, durationToMoment } from '~/helpers/dateAndTime'
import { useWeek, useTimeSlot } from '~/refdata'
import useShowLoader from '../Loader'

// --vars
import {
  ages,
  defaultAge,
  fromValue
} from '~/constants/course'
import SearchResultCard from '../shared/SearchResultCard'

// --components
export function Course (props) {
  const {
    course,
    students,
    cart,
    goToAddStudent,
    preSelected = null,
    closeCards
  } = props

  const [globalError, setGlobalError] = useState(null)
  const [addButtonDisabled, setAddButtonDisabled] = useState(false)
  const [removeButtonDisabled, setRemoveButtonDisabled] = useState(false)
  const [selectedSection, setSelectedSection] = useState(preSelected)

  useEffect(() => {
    if (closeCards) {
      setSelectedSection(null)
    }
  }, [closeCards])

  const showLocationWarning = selectedSection != null &&
    cart.reservationsByType.SQ.length > 0 &&
    selectedSection.campus != null &&
    cart.sqCampuses[selectedSection.campus] == null

  return (
    <SearchResultCard
      htmlId={`c${course.id}`}
      description={course.description}
      title={course.courseName}
      warningMessage={showLocationWarning ? 'Warning: This class is located at a different campus from other classes in your cart' : null}
      errorMessage={globalError}
      {...{ setSelectedSection, selectedSection }}
    >
      {selectedSection == null ? (
        <>
          {course.sections.map(section => (<SectionSearchResult {...{ section, setSelectedSection, course }} key={section.id} />))}
        </>
      ) : (
        <SelectedSection {...{
          cart,
          selectedSection,
          showLocationWarning,
          students,
          course,
          goToAddStudent,
          setRemoveButtonDisabled,
          removeButtonDisabled,
          setGlobalError,
          parentProps: props,
          addButtonDisabled,
          setAddButtonDisabled
        }}
        />
      )}
    </SearchResultCard>
  )
}

function SelectedSection ({
  selectedSection,
  showLocationWarning,
  students,
  course,
  goToAddStudent,
  setRemoveButtonDisabled,
  removeButtonDisabled,
  setGlobalError,
  parentProps,
  addButtonDisabled,
  setAddButtonDisabled,
  cart
}) {
  const enrollable = selectedSection.notEnrollableReason == null
  const [fetchedSection, setFetchedSection] = useState(null)
  const [prices, setPrices] = useState({ tuitionPrice: 0, suppliesPrice: 0, bookPrice: 0, totalPrice: 0 })

  const showLoader = useShowLoader()

  useEffect(() => {
    if (fetchedSection != null) {
      const { tuitionPrice, suppliesPrice, bookPrice } = fetchedSection
      setPrices({ tuitionPrice, suppliesPrice, bookPrice, totalPrice: tuitionPrice + suppliesPrice + bookPrice })
    }
  }, [fetchedSection])

  useEffect(() => {
    setGlobalError(null)
    if (selectedSection != null) {
      fetchSection(setFetchedSection, setGlobalError, selectedSection.id)
    } else {
      setFetchedSection(null)
    }
  }, [selectedSection, cart.reservationsByType.SQ])

  const week = useWeek(selectedSection.weekId)
  const timeSlot = useTimeSlot(selectedSection.timeSlotId)

  const cartContentsBySection = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.section.id} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const cartContentsByTime = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.startDate} ${r.startTime} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const { capacityDisplay, capacityClassName, isFull } = fetchedSection != null
    ? {
      capacityDisplay: `${fetchedSection.availableCapacity} seat${fetchedSection.availableCapacity > 1 ? 's' : ''} open`,
      capacityClassName: fetchedSection.availableCapacity <= 0 ? 'full' : fetchedSection.availableCapacity <= 3 ? 'near-full' : 'not-full',
      isFull: fetchedSection.availableCapacity <= 0
    }
    : {
      capacityDisplay: '0 seats open',
      capacityClassName: '',
      isFull: false
    }
  const cartContentsByCourse = cart.reservationsByType.SQ.reduce((acc, r) => {
    acc[`${r.courseId} ${r.studentPublicId}`] = r
    return acc
  }, {})

  const studentCourseDuplicates = (selectedSectionId) =>
    students.filter((student) =>
      cartContentsByCourse[`${course.id} ${student.publicId}`] != null &&
      cartContentsBySection[`${selectedSectionId} ${student.publicId}`] == null
    )

  const { startDate, endDate } = useWeek(selectedSection.weekId)
  const { startTime, endTime } = useTimeSlot(selectedSection.timeSlotId)

  return (
    <>
      {studentCourseDuplicates(selectedSection.id).map((student) =>
        <ErrorMessage key={`dup-${course.id}-${student.publicId}`} warning message={`Warning: Your cart already contains an enrollment into this class for ${student.firstName} ${student.lastName} `} />
      )}
      <div className='item row course-section-data'>
        <span>#{selectedSection.courseSectionNumber}</span>
        <span className={capacityClassName}>{capacityDisplay}</span>
      </div>
      <div className='item row selected-section'>
        <span>
          <div>Tuition</div>
          <div>{formatCurrency(prices.tuitionPrice)}</div>
        </span>
        <span>
          <div>Supplies</div>
          <div>{formatCurrency(prices.suppliesPrice)}</div>
        </span>
        <span>
          <div>Book</div>
          <div>{formatCurrency(prices.bookPrice)}</div>
        </span>
      </div>
      <div className='item row selected-section total-price'>
        <span>Total Price: {formatCurrency(prices.totalPrice)}</span>
      </div>
      <div className='item row selected-section'>
        <span className='info'>{`${week.name}, ${terseDate(week.startDate)}-${terseDate(week.endDate)}`}</span>
        <span className='info'>{`${durationToMoment(timeSlot.startTime).format('h:mm')}-${durationToMoment(timeSlot.endTime).format('h:mm A')}`}</span>
      </div>
      <div className='item row selected-section'>
        <span className={`info ${showLocationWarning ? 'location-warning' : ''}`}>
          {`${selectedSection.campus} Campus`}
        </span>
        <span className='info'>{fromValue(selectedSection.ageGroup, ages, defaultAge).label}</span>
      </div>

      {!enrollable && (
        <>
          <div className='item row no-student'>
            {selectedSection.notEnrollableReason}
          </div>
          <div className='item row no-student'>
              Please give us a call at 405-717-4900 between the hours of 8:00 AM and 4:30 PM and we'll assist you.
          </div>
        </>
      )}

      {students.length === 0 && enrollable && (
        <>
          <div className='item row no-student'>Let's start by registering a student.</div>
          <div className='item row no-student'>
            <Button
              label='Add Student'
              icon='person_add'
              unelevated
              onClick={goToAddStudent}
            />
          </div>
        </>
      )}

      {enrollable && students.map(student => {
        const { min, max } = fromValue(selectedSection.ageGroup, ages, defaultAge)

        const age = moment(week.startDate).diff(student.birthDate, 'years')

        const ageError = age < min ? 'Too young' : age > max ? 'Too old' : null

        const reservation = cartContentsBySection[`${selectedSection.id} ${student.publicId}`]
        const timeError = reservation == null && cartContentsByTime[`${week.startDate} ${timeSlot.startTime} ${student.publicId}`] != null ? 'Time Conflict' : null

        const getAction = function () {
          if (reservation != null) {
            // Remove from cart
            return (
              <span>
                <Button
                  label='Remove'
                  outlined
                  dense
                  disabled={removeButtonDisabled}
                  onClick={
                    wrapButtonToggle(
                      setRemoveButtonDisabled,
                      async () => {
                        showLoader(true)
                        await removeFromCart(parentProps, setGlobalError, reservation, student)
                        showLoader(false)
                      })
                  }
                />
              </span>
            )
          } else if (ageError != null) {
            return <span>{ageError}</span>
          } else if (timeError != null) {
            return <span>{timeError}</span>
          } else {
            // Add to cart
            if (isFull) {
              return <span>Full</span>
            }
            return (
              <span>
                <Button
                  label='Add to Cart'
                  unelevated
                  dense
                  disabled={addButtonDisabled}
                  onClick={
                    wrapButtonToggle(
                      setAddButtonDisabled,
                      async () => {
                        showLoader(true)
                        await addToCart(parentProps, setGlobalError, student, selectedSection, startDate, endDate, startTime, endTime)
                        showLoader(false)
                      })
                  }
                />
              </span>
            )
          }
        }

        return (
          <div className='item row student' key={`student-selection-${student.publicId}`}>
            <span className='cell'>{`${student.firstName} ${student.lastName}`}</span>
            {getAction()}
          </div>
        )
      })}

    </>
  )
}

// --functions
async function fetchSection (setFetchedSection, setGlobalError, id) {
  const response = await http(setGlobalError, http => http.get(`/api/SelfService/CourseSection/${id}`))
  setFetchedSection(response.data)
}

async function addToCart (props, setGlobalError, student, section, startDate, endDate, startTime, endTime) {
  var token = null
  try {
    token = await recaptcha('addToCart')
  } catch {
    // silently fail on recaptcha error
    return
  }

  const payload = {
    recaptchaToken: token,
    cartPublicId: props.cart.publicId,
    studentPublicId: student.publicId,
    sectionId: section.id
  }

  try {
    const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/AddEnrollment', payload))
    props.dispatch({
      type: 'ADD_RESERVATION',
      data: {
        cart: response.data.cart,
        reservations: response.data.reservations,
        newReservationData: {
          courseId: props.course.id,
          courseName: props.course.courseName,
          section: {
            id: section.id,
            ageGroup: section.ageGroup,
            availableCapacity: section.availableCapacity,
            campus: section.campus,
            courseSectionNumber: section.courseSectionNumber,
            price: section.price,
            notEnrollableReason: section.notEnrollableReason,
            startDate: `${fullDate(startDate)}-${fullDate(endDate)}`,
            startTime: `${durationToMoment(startTime).format('H:mm')}-${durationToMoment(endTime).format('H:mm A')}`
          },
          studentName: `${student.firstName} ${student.lastName}`,
          studentPublicId: student.publicId,
          type: 'SQ'
        }
      }
    })
  } catch (err) {
    if (err.response != null && err.response.status === 400) {
      setGlobalError(err.response.data.message)
    } else if (err.response != null && err.response.status === 409) {
      setGlobalError('This student already has a reservation in this class.')
    } else if (err.response != null && err.response.status === 424) {
      setGlobalError('It looks like you\'re going pretty fast! Please wait a moment and try again, or contact us at 405-717-4900 to enroll.')
    }
  }
}

async function removeFromCart (props, setGlobalError, reservation, student) {
  const payload = {
    cartPublicId: props.cart.publicId,
    reservationPublicId: reservation.publicId,
    studentPublicId: student.publicId
  }

  const response = await http(setGlobalError, http => http.post('/api/SelfService/Cart/RemoveEnrollment', payload))
  props.dispatch({
    type: 'REMOVE_RESERVATION',
    data: {
      cart: response.data.cart,
      reservations: response.data.reservations
    }
  })
}

function formatCurrency (amount) {
  return `$ ${parseFloat(amount).toFixed(2)}`
}
// --mappings
export default connect(state => ({
  students: state.students.students,
  cart: state.cart
}))(Course)
